$bg-color: $secondary-black;
$btn-color: $accent-yellow;
$btn-color-hover: $accent-yellow-2;
$btn-font-color: black;
$text-color: white;

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: black;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#loginForm,
#signUpForm {
	background-color: $bg-color;
	color: $text-color;
	max-width: 450px;

	h1 {
		text-align: center;
	}
	.help-msg {
		margin: 0;
		text-align: center;
		font-size: 80%;
	}
	.row {
		display: flex;
		justify-content: space-between;
		align-items: center;

		a {
			margin-top: 0.5rem;
			color: currentColor;
		}
		label, a {
			font-size: clamp(12px, 2vw, 16px);
		}
	}
	.inputs {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		border-radius: 0.25rem;
		position: relative;
		input {
			border: none;
			outline: none;
			border-radius: 0;
			border-bottom: 1px solid #00000022;
			padding: 0.75rem 1rem;
			&:last-of-type {
				border: none;
			}
		}

		#togglePassword {
			color: black;
			position: absolute;
			bottom: 0;
			right: 0;
			margin: .9rem .5rem;
			font-size: 1.25rem;
			cursor: pointer;
		}
	}
	.remember-input {
		display: flex;
		align-items: center;
		margin-top: .5rem;
		gap: .25em;
	}
	.login,
	.signIn {
		color: $btn-font-color;
		background-color: $btn-color;
		border: none;
		width: 100%;
		padding: 0.75rem;
		margin: 1rem 0;
		margin-top: .5rem;
		border-radius: 0.25rem;
		cursor: pointer;
		&:hover,
		&:focus-within {
			background-color: $btn-color-hover;
		}
	}
	.form-error {
		color: #e74c3c;
		text-align: center;
        text-decoration: underline;
		margin: 0;
		margin-top: 1rem;
	}
}

#loginForm,
#signUpForm {
	width: clamp(30vw, 25rem, 90vw);
	font-size: clamp(1.25rem, 2.5vw, 1.5rem);
	padding: 5rem 3rem;
	box-sizing: border-box;

	border-radius: 0.75rem;
	box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

	@media screen and (max-width: 380px) {
		padding-inline: 1rem;
	}
	.loginOption {
		background-color: transparent;
		color: currentColor;
		width: 100%;
		border: none;
		padding: 0;
		margin: 0;
		cursor: pointer;
		&:hover,
		&.focus-within {
			text-decoration: underline;
		}
		a {
			color: currentColor;
			text-decoration: none;
			&:active {
				filter: brightness(0.6);
			}
		}
	}
}