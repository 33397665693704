$defaultChartWidth: 26vw;

#community-select-container {
    width: $container-width-2;
    margin: 2rem auto;

    p {
        font-size: clamp(1.25rem, 2vw, 1.5rem);
        text-align: center;
    }


    .community-option-container {
        display: flex;
        gap: .5rem;
        justify-content: center;
        flex-wrap: wrap;
    }
}

#dashboard-container {
    width: $container-width-2;
    margin: 2rem auto;
    filter: drop-shadow(rgba(0, 0, 0, 0.24) 0px 3px 8px);

    #dashboard-header {
        background-color: #FFF;
        // padding: 1rem;
        // border-radius: 8px;

        .header-row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: .5rem;
            margin: 0 1rem;
            padding-top: 1rem;
        }

        .chart-row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem;
            margin: 1rem;

            min-width: 1000px;
        }

        h2 {
            margin: 0;
            font-size: clamp(1rem, 8vw, 2rem);
        }
    }

    .dashboard-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .5rem;
    }
}

.chart-card {
    border: 2px solid #000000AA;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 0.5rem;

    box-shadow: 3px 2px 8px #00000033;

    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-family: Proxima-nova;
    }

    h3 {
        text-align: center;
        margin: 0.5rem;
    }

    .kpi-card {
        display: grid;
        grid-template-columns: 1fr 2fr;

        .kpi-data {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                line-height: 0.8;
                margin: 0;
                margin-top: auto;
            }

            p {
                margin: 0;
                font-size: 1.15rem;
                margin-bottom: .5rem;
            }
        }

        .kpi-chart {
            // width: 100%;
            // width: max-content;

            canvas {
                max-width: 100%;
            }
        }
    }
}

#table-container {
    display: grid;
    place-items: center;
    font-size: clamp(14px, 2.25vw, 16px);
    margin: 1rem auto;

    p {
        font-size: inherit;
        white-space: nowrap;
    }

    #table-header {
        display: flex;
        width: 100%;
        font-weight: bold;
        background-color: $primary-black;
        color: white;
        border-bottom: 2px solid $secondary-black;

        p {
            border-left: 2px solid $secondary-black;

            &:first-of-type {
                border-left: none;
            }

            padding: .5em;
            margin: 0;
            flex: 1 1 0px;

            &.date,
            &.hours {
                flex-grow: 2;
            }
        }
    }

    #roster-table {
        position: relative;
        // min-width: max-content; 
        width: 100%;
        max-height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
        filter: drop-shadow(rgba(0, 0, 0, 0.24) 0px 3px 8px);

        /* width */
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: #f6f6f6;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #2e2d2b;
            border-radius: 5px;
            border: 1px solid #f6f6f6;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #4e4d4b;
            cursor: pointer;
        }

        .row {
            display: flex;
            width: 100%;
            position: sticky;

            &:nth-child(odd) {
                background-color: #f6f6f6;
            }

            &:nth-child(even) {
                background-color: #ffffff
            }

            &.day {
                cursor: pointer;
                margin-block: 1px;

                &:nth-child(4n) {
                    background-color: #f6f6f6;
                }
            }

            &.highlight {
                outline: 2px solid $accent-green;
            }

            &#header {
                font-weight: bold;
                background-color: $primary-black;
                color: white;
                border-bottom: 2px solid $secondary-black;

                p {
                    border-left: 2px solid $secondary-black;

                    &:first-of-type {
                        border-left: none;
                    }
                }
            }

            p {
                padding: .5em;
                margin: 0;
                flex: 1 1 0px;

                &.date,
                &.hours {
                    flex-grow: 2;
                }

                // signups
                // hours
            }
        }

        .dropdown-table {
            margin-block: 1px;
            // min-width: max-content;
            width: 100%;

            height: 0px;
            overflow: hidden;
            overflow-x: auto;

            .dropdown-scroll-container {
                min-width: max-content;
                width: 100%;
                margin-left: 16px;
            }

            &.open {
                height: auto;
            }

            p {
                text-align: left;

                &.name {
                    flex-grow: 1;
                }

                &.datetime {
                    flex-grow: 2;
                }

                &.email {
                    flex-grow: 2;
                }

                &.phone {
                    flex-grow: 1;
                }
            }
        }
    }

    #csv-download {
        margin: 1rem 0;
        margin-left: auto;
    }

    #table-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        width: 100%;
        font-family: Proxima-nova;

        #csv-download {
            font-size: 1em;
            margin: 0;
        }

        p {
            margin: 0;
            font-weight: bold;
        }
    }

}

#prayer-point-form {
    // flex: 1;
    width: 100%;
    margin: 0 auto;
    font-family: Proxima-nova, sans-serif;

    .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .5rem;
    }

    .input-container {
        display: flex;
        flex-direction: column;

        textarea {
            resize: none;
            border: none;
            background-color: white;
            font-size: $form-font-size;
            border-radius: 2px;
            outline: none;
            padding: 4px 2px;

            height: 160px;
            box-sizing: border-box;
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        gap: .5rem;

        button {
            font-size: 1em;
            margin: 0;
            padding: .5rem 1.5rem;

            &:disabled {
                display: none;
                visibility: hidden;
            }
        }
    }
}

#reservation-table-container {
    display: grid;
    place-items: center;
    // flex: 1;
    width: 100%;

    margin: 0 auto;
    font-family: Proxima-nova, sans-serif;

    #reservation-table {
        display: flex;
        flex-direction: column;
        width: 100%;
        filter: drop-shadow(rgba(0, 0, 0, 0.24) 0px 3px 8px);

        .data-row {
            display: flex;

            // justify-content: space-between;
            &:not(.header) {
                margin-bottom: 4px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &:nth-child(odd) {
                background-color: #f6f6f6;
            }

            &:nth-child(even) {
                background-color: #ffffff
            }

            &.header {
                font-weight: bold;
                background-color: $primary-black;
                color: white;

                p {
                    border-bottom: 2px solid $secondary-black;
                    border-right: 2px solid $secondary-black;

                    &:last-of-type {
                        border-right: none;
                    }
                }
            }

            .date-label,
            p {
                padding: .5em;
                margin: 0;
                margin-right: auto;
            }

            .btn {
                border: none;
                background-color: transparent;
                margin: 0;
                padding: 0 .5rem;

                &.reservation-edit-btn {
                    color: #27ae60;
                }

                &.reservation-save-btn {
                    color: #27ae60;
                }

                &.reservation-cancel-btn {
                    color: #e74c3c;
                }

                &.reservation-delete-btn {
                    color: #34495e;
                }
            }
        }
    }

    #new-prayer-day-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        p {
            margin: 0;
            text-align: left;
        }

        .input-container {
            display: flex;
            justify-content: space-between;
            padding: .5rem 0;

            button {
                font-size: 1em;
                margin: 0;
                padding: .5rem 1.5rem
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #dashboard-container .dashboard-row {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 525px) {
    #dashboard-container #dashboard-header {
        // padding: 1rem;

        .chart-row {
            min-width: 0;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 1fr);
            // margin-bottom: 6px;
        }
    }
}