.input-option {
    align-items: flex-start;
    display: flex;
    gap: .5rem;
    .options {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: .5rem;
    }
    &:not(:last-of-type) {
        margin-bottom: .5rem;
    }
    span {
        color: white;
    }
    input[type="number"] {
        width: 3rem;
        color: black;
    }
    input[type="radio"] {
        height: 100%;
    }
    select, input {
        height: 29px;
        padding-block: 0 !important;
        &:disabled, &:disabled {
            opacity: .5;
        }
    }
}
#date-container {
    color: white;
    position: absolute;
    top: 0;
    right: 0;

    display: grid;
    place-items: center;

    &.disabled {
        opacity: .5;
        &::after {
            display: none;
        }
    }
    &.highlight::after {
        border-color: $accent-yellow;
    }
    &::after {
        content: "";
        position: absolute;

        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 18px;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 50%;
    }

    #dates {
        z-index: 2;
        position: absolute;
        background-color: $primary-black;

        padding: .5rem 1rem;
        margin: 0;

        border-radius: .5rem;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 8px 0px;

        list-style: none;
        
        top: -4px;
        right: -4px;

        width: max-content;

        display: none;
        visibility: hidden;
    }

    &:not(.disabled):hover, &:not(.disabled):focus {
        .hover-icon {
            outline: none;
        }
        #dates {
            display: block;
            visibility: visible;
        }
    }
}