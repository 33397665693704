@font-face {
	font-family: Proxima-nova;
	src: url('../assets/Proxima-Nova.otf') format('opentype');
}

@font-face {
	font-family: Tropoline;
	src: url('../assets/Tropiline-Black.otf') format('opentype');
}


body,
html {
	font-family: Tropoline;
	min-height: 100dvh;
	width: 100%;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	scroll-behavior: smooth;

	background-color: $primary-white;
}

#root {
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
}

p,
ol,
ul,
li {
	font-family: Proxima-nova;
}

#back-btn {
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #2980b9;
}

.container {
	width: $container-width;
	margin: 0 auto;
	padding: 1rem;
	box-sizing: border-box;
}

.grid-center {
	display: grid;
	place-items: center;
}

.btn {
	display: block;

	font-family: Proxima-nova;
	font-weight: 300;
	font-size: clamp(1rem, 2vw, 1.25rem);
	text-decoration: none;
	color: $primary-white;
	border: none;
	cursor: pointer;

	background-color: $primary-black;

	&.highlight {
		background-color: $accent-yellow;
		color: $primary-black;
	}

	&.icon {
		display: grid;
		place-items: center;
		font-size: clamp(1.25rem, 2vw, 1.5rem);
		padding: .5rem .75rem;
	}

	width: max-content;
	padding: .75rem 1.25rem;
	border-radius: .25rem;


	transition: transform 350ms cubic-bezier(.8, .5, .2, 1.4),
	opacity 400ms linear;

	&:hover {
		transform: translateY(-2px);
	}
}

.scroll-container {
	overflow: auto;

	/* width */
	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		background-color: #f6f6f6;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		border-radius: 5px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #2e2d2b;
		border-radius: 5px;
		border: 1px solid #f6f6f6;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #4e4d4b;
		cursor: pointer;
	}
}