$icon-accent-color: $accent-yellow;

#loadingScreen {
    position: fixed;
    inset: 0;
    background-color: #000000AA;
    color: white;
    font-size: clamp(1rem, 6vw, 2rem);

    display: grid;
    place-items: center;

    // visibility: hidden;
    // display: none;
    z-index: 999;

    h3 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }

    // #percent-complete {
    //     display: none;
    //     visibility: hidden;
    // }
    #loading-bar {
        width: 105px;
        height: 105px;
        border-radius: 50%;
        // background-color: red;
        position: absolute;
        overflow: hidden;

        #progress {
            width: 100%;
            height: 0%;
            background-color: $icon-accent-color;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}

$spinner-time: 1s;

.loader {
    /* Light grey */
    border: 16px solid #00000022;
    /* Blue */
    border-right: 16px solid $icon-accent-color;
    border-left: 16px solid $icon-accent-color;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin $spinner-time linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}