$form-gap: 1rem;

#sign-up-form {
    display: flex;
    flex-wrap: wrap;
    gap: $form-gap;
    width: 1280px;
    max-width: 90vw;
    margin: $form-gap auto;
    font-family: Proxima-nova;
    position: relative;
    font-size: clamp(1rem, 2vw, 1.25rem);

    &>#back-btn {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #2980b9;
    }

    &>#prayer-date {
        width: 100%;
        text-align: center;
        margin: 0;
        // margin-top: 1rem;
    }

    &>#community-champions {
        width: 100%;
        text-align: center;
        margin: 0;
        margin-bottom: 1rem;
    }

    &>.input-container {
        display: flex;
        flex-direction: column;
        width: calc(50% - ($form-gap / 2));

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        &.full-width {
            width: 100%;
        }

        input {
            padding: .5rem .25rem;
            border: none;
            border-radius: 2px;
        }

        &>textarea {
            resize: none;
            border: none;
        }
    }

    &>.recurring-container {
        width: 100%;
        display: flex;
        margin-top: .5rem;

        input[type="checkbox"] {
            margin-inline: auto .5rem;

            &:checked~label span {
                opacity: 1;
            }
        }

        label span {
            opacity: .5;
        }
    }

    &>.dropdown-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        select {
            padding: .5rem .25rem;
            border: none;
            border-radius: 2px;
            background-color: white;
            color: black;
        }
    }

    &>.time-container {
        width: 100%;
        overflow-x: auto;

        @media screen and (max-width: 768px) {
            padding-bottom: 10px;
        }
    }

    &>.recurring-container {
        display: flex;
        position: relative;
        margin-right: 1.2em;

        // transform: translateX(-1.2em);
        input,
        select {
            padding: .5rem .25rem;
            margin: 4px;
            border: none;
            border-radius: 2px;
            background-color: white;
            color: black;
        }

        input[type="number"] {
            width: 50px;
        }

        input:disabled,
        select:disabled {
            opacity: .5;
        }

        input[type="checkbox"]:not(:checked)~#update-icon-container {
            opacity: .5;

            #update-date-container {
                visibility: hidden !important;
            }
        }

        label {
            height: max-content;
            margin: auto 0;
            white-space: normal;
            max-width: 90vw;
        }

        label:last-of-type {
            text-align: right;
        }

        #update-icon-container {
            background-color: white;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(100%, -50%);
            z-index: 9;

            border-radius: 50%;
            font-size: .6em;
            height: 2em;
            width: 2em;

            display: grid;
            place-items: center;

            &.highlight {
                outline: 2px solid $accent-yellow;
            }

            &:hover #update-date-container {
                visibility: visible;
            }

            #update-date-container {
                visibility: hidden;
                position: absolute;
                top: 0;
                right: 0;

                width: max-content;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                padding: 1rem;

                background-color: white;
                border-radius: 4px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                font-size: 1rem;

                p {
                    margin: 0;
                }
            }
        }

        @media screen and (max-width: 964px) {
            flex-wrap: wrap;
            margin-right: 0;

            label:last-of-type {
                width: 100%;
                text-align: left;
                white-space: wrap;
            }

            input[type="checkbox"] {
                margin-inline: .5rem auto;
            }

            #update-icon-container {
                transform: translate(0%, -50%);
            }
        }
    }

    &>.reminder-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: .5rem;

        .reminder-option {
            input {
                margin: .5rem;
            }

            &:first-of-type {
                margin-left: auto;
            }
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;

            .reminder-option {
                margin: 0 !important;
            }
        }
    }

    #hours-options {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        margin: 0 auto;
        gap: .5rem;

        // width: max-content;
        &>.checkbox-container {
            display: grid;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 2px;
            position: relative;

            &>input[type='radio'] {
                // display: none;
                // visibility: hidden;
                opacity: 0;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                &:focus-within~label {
                    outline: 2px solid black;
                }

                &:checked~label {
                    background-color: $accent-blue;
                    outline: 2px solid $accent-green;
                    // border: 2px solid $accent-blue;
                }

                &.covered~label {
                    background-color: $accent-blue;
                }
            }

            &>label {
                background-color: #FFF;
                padding: .5rem;
                user-select: none;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
                white-space: nowrap;
            }

        }
    }

    &>.form-footer {
        display: flex;
        width: 100%;

        &>#curr-time {
            margin: .5rem 0;
            line-height: 30px;
        }

        &>button[type="submit"] {
            margin-left: auto;
        }
    }
}