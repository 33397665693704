nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: $container-width;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
	font-family: Proxima-nova;
  position: relative;
  z-index: 99;

  .logo-container {
    width: clamp(100px, 14vw, 150px);
    img {
      max-width: 100%;
      display: block;
    }
  }
  
  #links-container {
    display: flex;
    box-sizing: border-box;
    .link {
      height: max-content;
      transition: transform 350ms cubic-bezier(.8, .5, .2, 1.4), opacity 400ms linear;
      &:hover {
        transform: translateY(-2px);
      }
      &.highlight a {
        background-color: $accent-yellow;
      }
      a {
        display: block;
        text-decoration: none;
        color: $primary-black;
        font-size: clamp(12px, 1.5vw, 18px);
        padding: .75rem 2rem;
        border-radius: .25rem;
      }
    }
    #logout-btn {
      display: none;
      visibility: hidden;
    }
  }

  .toggle-nav {
    display: none;
    visibility: hidden;
    #toggle-nav-btn {
      display: grid;
      place-items: center;
      padding: .5rem;
      background-color: $accent-yellow;
      border-radius: .25rem;
      border: none;
      i {
        font-size: 28px;
        color: $primary-black;
      }
       
      &:active {
        transform: scale(.9);
      }
    }
  }
  @media screen and (max-width: 768px) {
    .toggle-nav {
      display: block;
      visibility: visible;
    }

    #links-container {
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 100%;
      left: 100vw;

      transition: left 0ms linear;
      transition-delay: 600ms;
      &.open {
        left: 0;
        transition-delay: 0ms;
      }

      // padding: 0 1rem;
      // gap: 4px;

      .link {
        transform: translateX(100vw);
        opacity: 0;
        a {
          text-align: center;
          font-size: 20px;
          background-color: $primary-white;
        }
      }
    }

    #links-container.open .link {
      transform: translateX(0);
      opacity: 1;

      &:first-of-type {
        border-top: 2px solid $primary-black;
      }
      &:last-of-type {
        border-bottom: 2px solid $primary-black;
      }

      @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
          transition-delay: 100ms * $i;
        }
      }
    }
  }
}