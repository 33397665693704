.about-us {
    width: clamp(50vw, 1280px, 90vw);
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    gap: 1rem;

    h1 {
        font-size: clamp(1.75rem, 5vw, 3rem);
        margin: 0;
    }

    p {
        font-size: clamp(1rem, 2vw, 1.25rem);
        margin: 0;
    }

    .flex-1 {
        flex: 1;
    }

    .flex-2 {
        flex: 2;
    }

    a.contact-btn {
        background-color: $accent-yellow;
        color: currentColor;
        margin-left: auto;
        margin-block: 1rem;
    }

    .video-container {
        display: flex;
        justify-content: center;
        margin: 1rem auto;

        iframe {
            width: clamp(50vw, 1280px, 90vw);
            aspect-ratio: 16/9;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

#mission-statement h1 {
    text-align: justify;
    font-size: clamp(1rem, 5vw, 2rem);
}