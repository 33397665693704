#leader {
    width: clamp(50vw, 1280px, 90vw);
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    &.list {
        p {
            // text-align: justify;
            padding-right: .5rem;
            border-right: 1px solid #00000044;
            &:last-of-type {
                padding: 0;
                border: none !important;
            }
            &:not(:first-of-type) {
                padding-left: .5rem;
            }
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            // width: 500px;
            p {
                padding: 1rem 0 !important;
                border: none !important;
                border-bottom: 1px solid #00000044 !important;
            }
        }
    }

    h1 {
        font-size: clamp(1.5rem, 5vw, 2rem);
        margin: 0;
    }
    p {
        font-size: clamp(1rem, 2vw, 1.25rem);
        margin: 0;
    }
    .col {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    a.register-btn {
        background-color: $accent-yellow;
        color: currentColor;
        margin-left: auto;
        margin-block: 1rem;
    }
    .video-container {
        display: flex;
        justify-content: center;
        margin: 1rem auto;
        iframe {
            width: clamp(50vw, 1280px, 90vw);
            aspect-ratio: 16/9;
        }
    }
}

// .video-container {
//     display: flex;
//     justify-content: center;
//     margin: 1rem 0;
//     iframe {
//         width: 800px;
//         max-width: 100vw;
//         aspect-ratio: 16/9;
//     }
// }

// #leader-header {
//     margin: 1rem auto;
//     box-sizing: border-box;
//     width: 100%;
    
//     display: flex;
//     flex-direction: column;
//     h3 {
//         // text-align: center;
//         margin: 1rem auto;
//         width: 750px;
//         font-weight: 500;
//         font-size: 1.5rem;
//         max-width: 90vw;
//     }
//     ol {
//         list-style: none;
//         width: 1000px;
//         max-width: 90vw;
//         box-sizing: border-box;
//         margin: 0 auto;
//         display: flex;
//         justify-content: center;
//         padding: 1rem 0;
//         li {
//             width: 100%;
//             max-width: 95vw;
//             padding-block: .5rem;
//             &:not(:last-of-type) {
//                 border-right: 1px solid #00000044;
//                 padding-right: .5rem;
//             }
//             &:not(:first-of-type) {
//                 padding-left: .5rem;
//             }
//         }
//         @media screen and (max-width: 768px) {
//             flex-direction: column;
//             width: 500px;
//             li {
//                 padding: 1rem 0 !important;
//                 border: none !important;
//                 border-bottom: 1px solid #00000044 !important;
//             }
//         }
//     }
// }
// .register-btn {
//     background-color: $accent-yellow;
//     color: currentColor;
//     margin-left: auto;
//     margin-block: 1rem;
// }